import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from '../services/product';
import {ProductRelation} from '../services/product-relation';
import {ProductSelectModalComponent} from '../product-select-modal/product-select-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductRelationGroupEditModalComponent} from '../product-relation-group-edit-modal/product-relation-group-edit-modal.component';
import {ProductRelationsGroup} from '../services/product-relations-group';
import {ProductRelationsGroupsService} from '../services/product-relations-groups.service';
import {faNoteSticky, faGears} from '@fortawesome/free-solid-svg-icons';
import {ProductRelationEditModalComponent} from '../product-relation-edit-modal/product-relation-edit-modal.component';
import {ProductRelationsService} from "../services/product-relations.service";
import {PriceGroupsService} from "../services/price-groups.service";
import {Price} from "../services/price";
import {PriceGroup} from "../services/price-group";

@Component({
    selector: 'app-product-child-relations-edit',
    templateUrl: './product-child-relations-edit.component.html',
    styleUrls: ['./product-child-relations-edit.component.scss'],
    providers: [ProductRelationsGroupsService, ProductRelationsService, PriceGroupsService]
})
export class ProductChildRelationsEditComponent implements OnInit {


    // tslint:disable-next-line:variable-name
    @Input() _product: Product;
    @Input() saving = false;
    @Output() loadProduct: EventEmitter<any> = new EventEmitter();

    faNote = faNoteSticky;
    faGears = faGears;

    public loading = true;
    public linkedPriceGroup: PriceGroup;
    searchQuery: any;

    constructor(
        public priceGroupsService: PriceGroupsService,
        public ngbModal: NgbModal,
        public productRelationsGroupsService: ProductRelationsGroupsService,
        public productRelationsService: ProductRelationsService,
    ) {
    }

    ngOnInit(): void {
        this.priceGroupsService.getPriceGroups(1, 2, {forProductId: this._product.id}).subscribe((resut) => {
            if (resut.data.length > 0) {
                this.linkedPriceGroup = resut.data[0];
            }

            this.loading = false;
        });
    }

    public addProductRelation(childRelation: boolean): void {
        const self = this;

        const productRelation = ProductRelation.fromJSON({
            childRelation: childRelation,
            product_id: childRelation ? this._product.id : null,
            related_product_id: childRelation ? null : this._product.id,
            note: '',
            is_standard: false,
            product: this._product
        });

        const modalRef = this.ngbModal.open(ProductSelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.productRelatable = productRelation;
        modalRef.componentInstance.selected = (productRelation) => {
            // @todo perhaps feature_flag ?
            // @todo links related child and set a price on that relation, takes standard price, og
            if (this.linkedPriceGroup) {

                if (productRelation.related_product.prices.find((item) => {
                    return item.price_group_id === this.linkedPriceGroup.id;
                })) {
                } else {

                    // Get the standard price, or the highest other price
                    let standardPrice = productRelation.related_product.prices.find((item) => {
                        return item.is_default === true;
                    });
                    if (!standardPrice) {
                        standardPrice = productRelation.related_product.prices.sort((a, b) => {
                            return a.price < b.price ? 1 : -1;
                        }).find(Boolean);
                    }

                    productRelation.related_product.prices.push(Price.fromJSON({
                        price_group_id: this.linkedPriceGroup.id,
                        product_id: productRelation.related_product.id,
                        price: standardPrice ? standardPrice.price : 0,
                        price_group: this.linkedPriceGroup
                    }));
                }
            }
            if (childRelation) {
                self._product.product_relation_childrens.push(productRelation);
                self._product.product_relation_childrens = [...self._product.product_relation_childrens];
            } else {
                self._product.product_relation_parents.push(productRelation);
                self._product.product_relation_parents = [...self._product.product_relation_parents];
            }
            this.searchQuery = ' ';
            this.searchQuery = undefined;
        };
    }

    addProductRelationsGroup(): void {
        const self = this;

        const productRelationsGroup = new ProductRelationsGroup();
        productRelationsGroup.product_id = this._product.id;
        productRelationsGroup.is_dependent = false;
        productRelationsGroup.is_mutually_exclusive = false;
        productRelationsGroup.name = 'Group';

        this._product.product_relations_groups.push(productRelationsGroup);
        const modalRef = this.ngbModal.open(ProductRelationGroupEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.productChildRelations = this._product.product_relation_childrens;
        modalRef.componentInstance.productRelationsGroup = productRelationsGroup;
        modalRef.componentInstance.isNew = true;
        // tslint:disable-next-line:variable-name
        modalRef.componentInstance.delete = (_productRelationsGroup) => {
            this.deleteGroup(_productRelationsGroup);
        };

    }

    editProductRelationsGroup(productRelationsGroup: ProductRelationsGroup): void {
        const self = this;

        const modalRef = this.ngbModal.open(ProductRelationGroupEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.productRelationsGroup = productRelationsGroup;
        modalRef.componentInstance.productChildRelations = this._product.product_relation_childrens;
    }

    editProductRelation(productRelation: ProductRelation): void {
        const self = this;

        const modalRef = this.ngbModal.open(ProductRelationEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.productRelation = productRelation;
        // tslint:disable-next-line:variable-name
        modalRef.componentInstance.delete = (_productRelation) => {
            this.removeRelation(_productRelation);
        };
    }

    public deleteGroup(group: ProductRelationsGroup): void {
        this._product.product_relations_groups.splice(this._product.product_relations_groups.indexOf(group), 1);
    }

    public removeRelation(relation): void {
        this._product.product_relation_childrens.splice(this._product.product_relation_childrens.indexOf(relation), 1);
        this._product.product_relation_childrens = [...this._product.product_relation_childrens];
    }

}
