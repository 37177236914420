<h1 class="heading">{{title}}</h1>

<div class="alert  alert-info d-flex justify-content-between" style="gap: 1rem" *ngIf="offerService.selectedTool"><span>
    <span i18n>Current choice:</span>
    <strong>{{offerService.selectedTool.product.name}}</strong></span>

    <span *ngIf="offerService.selectedOrder">TMT: <strong>{{offerService.selectedOrder.getReference(idValuesService.tmtNumberId)}}</strong></span>
    <button *ngIf="offerService.selectedTool" (click)="offerService.reset()" class="btn btn-link ml-auto" i18n>Reset order</button>
</div>
<app-step-stones [stepStones]="offerSteps" [showStepStoneNavigation]="false" [activeStepIndex]="offerService.currentStepIndex" (shouldChangeStepIndex)="changeStep($event)" [canGoToNextStep]=""></app-step-stones>
<app-step-stones [stepStones]="offerSteps" [showStepStones]="false" [activeStepIndex]="offerService.currentStepIndex" (shouldChangeStepIndex)="changeStep($event)" [canGoToNextStep]="offerService.pausenext===false"></app-step-stones>
<router-outlet name='offer_outlet'></router-outlet>
<app-step-stones [stepStones]="offerSteps" [showStepStones]="false" [activeStepIndex]="offerService.currentStepIndex" (shouldChangeStepIndex)="changeStep($event)" [canGoToNextStep]="offerService.pausenext===false"></app-step-stones>
