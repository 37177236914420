import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Product} from "../services/product";
import {ProductRelation} from "../services/product-relation";
import {ProductSelectModalComponent} from "../product-select-modal/product-select-modal.component";
import {faNoteSticky, faGears} from "@fortawesome/free-solid-svg-icons";
import {ProductRelationEditModalComponent} from "../product-relation-edit-modal/product-relation-edit-modal.component";

@Component({
  selector: 'app-product-parent-relations-edit',
  templateUrl: './product-parent-relations-edit.component.html',
  styleUrls: ['./product-parent-relations-edit.component.scss']
})
export class ProductParentRelationsEditComponent implements OnInit {

    constructor(
        public ngbModal: NgbModal,
    ) {
    }

    // tslint:disable-next-line:variable-name
    @Input() _product: Product;
    @Input() saving = false;

    faNote = faNoteSticky;
    faGears = faGears;

    ngOnInit(): void {
    }

    public addProductRelation(childRelation: boolean): void {
        const self = this;

        const productRelation = ProductRelation.fromJSON({
            childRelation: childRelation,
            product_id: childRelation ? this._product.id : null,
            related_product_id: childRelation ? null : this._product.id,
            note: '',
            product: this._product
        });

        const modalRef = this.ngbModal.open(ProductSelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.productRelatable = productRelation;
        modalRef.componentInstance.selected = (productRelation) => {
            if (childRelation) {
                self._product.product_relation_childrens.push(productRelation);
            } else {
                self._product.product_relation_parents.push(productRelation);
            }
        };
    }

    editProductRelation(productRelation: ProductRelation): void {
        const self = this;
        // @todo parent relation edit modal
        const modalRef = this.ngbModal.open(ProductRelationEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.productRelation = productRelation;
    }

}
