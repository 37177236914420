import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../services/auth.service';
import {UnitsService} from '../services/units.service';
import {Unit} from '../services/unit';
import {LanguagesService} from "../services/languages.service";
import {Translation} from "../services/translation";
import {LocaleTranslation} from "../services/locale-translation";

@Component({
    selector: 'app-unit-edit-modal',
    templateUrl: './unit-edit-modal.component.html',
    styleUrls: ['./unit-edit-modal.component.scss'],
    providers: [UnitsService, LanguagesService]
})
export class UnitEditModalComponent implements OnInit {

    public unit: Unit;
    // tslint:disable-next-line:variable-name
    public _unit: Unit;

    public missingLocalizations;
    name: string[] = [];

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public unitsService: UnitsService,
                public authService: AuthService,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this._unit = this.unit ? Unit.fromJSON(this.unit) : Unit.fromJSON({is_default: false});
        this.languagesService.getLanguages().subscribe(() => {
            this.loadLanguages();
        });
    }

    loadLanguages(): void {
        let i = 0;
        this.languagesService.languages.forEach((language) => {
            if (this._unit._translations) {
                const translation = this._unit._translations[language.locale];
                if (translation) {
                    if (translation.name) {
                        this.name[i] = translation.name;
                    } else {
                        this.name[i] = '';
                    }
                } else {
                    this.name[i] = '';
                }
            } else {
                this.name[i] = '';
            }

            i++;
        });
    }

    close(): void {
        this.activeModal.dismiss();
    }

    save(): void {
        this.saving = true;
        this.saveLanguages();
        if (this.unit) {
            this.unitsService.updateUnit(this._unit).subscribe(unit => {
                this.saved();

                this.saving = false;

                this.activeModal.close(unit);
            });
        } else {
            this.unitsService.addUnit(this._unit).subscribe(unit => {
                this.saved();

                this.saving = false;

                this.activeModal.close(unit);
            });
        }
    }

    saveLanguages(): void {
        let i = 0;
        const translations: Translation = new Translation();
        this.languagesService.languages.forEach((language) => {
                const object = new LocaleTranslation();
                object.name = this.name[i];
                object.locale = language.locale;
                translations[language.locale] = object;
            this._unit._translations = translations;
            i++;
        });
    }
}
