import {Component, Input, OnInit} from '@angular/core';
import {ProductsService} from '../services/products.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Reference} from '../services/reference';
import {forkJoin} from 'rxjs';
import {ReferencesService} from '../services/references.service';
import {Product} from '../services/product';
import {CreditorsService} from '../services/creditors.service';
import {Creditor} from '../services/creditor';
import {PriceGroup} from '../services/price-group';
import {PriceGroupsService} from '../services/price-groups.service';
import {ProductCategorySelectModalComponent} from '../product-category-select-modal/product-category-select-modal.component';
import {ProductFieldsService} from '../services/product-fields.service';
import {ProductField} from '../services/product-field';
import {ProductPriceAddModalComponent} from '../price-add-modal/product-price-add-modal.component';
import {ProductSelectModalComponent} from '../product-select-modal/product-select-modal.component';
import {ProductRelation} from '../services/product-relation';
import {ProductStockAddModalComponent} from '../product-stock-add-modal/product-stock-add-modal.component';
import {VatType} from '../services/vat-type';
import {VatTypesService} from '../services/vat-types.service';
import {UnitsService} from '../services/units.service';
import {Unit} from '../services/unit';
import {ProductQuantityDiscountModalComponent} from '../product-quantity-discount-modal/product-quantity-discount-modal.component';
import {ProductQuantityDiscount} from '../services/product-quantity-discount';
import {AttachmentsService} from '../services/attachments.service';
import {AttachmentUploadModalComponent} from '../attachment-upload-modal/attachment-upload-modal.component';
import {Attachment} from '../services/attachment';
import {LanguagesService} from '../services/languages.service';
import {Translation} from '../services/translation';
import {LocaleTranslation} from '../services/locale-translation';
import {ProductsProductField} from '../services/products-product-field';
import {faXmark} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-product-edit',
    templateUrl: './product-edit.component.html',
    styleUrls: ['./product-edit.component.sass'],
    // tslint:disable-next-line:max-line-length
    providers: [ProductsService, CreditorsService, PriceGroupsService, ReferencesService, ProductFieldsService, VatTypesService, UnitsService, AttachmentsService, LanguagesService]
})
export class ProductEditComponent implements OnInit {


    @Input() productId;
    // tslint:disable-next-line:variable-name
    @Input() _product: Product;
    @Input() productFields: ProductField[];
    @Input() fieldTranslations;
    // tslint:disable-next-line:variable-name
    @Input() _products_product_fields: ProductsProductField[];
    @Input() missingLocalizations;
    @Input() creditors: Creditor[];
    @Input() vatTypes: VatType[];
    @Input() units: Unit[];
    @Input() priceGroups: PriceGroup[];
    @Input() name = [];
    @Input() saving = false;

    loading = false;

    // missingLocalizations;
   // fieldTranslations = [];
    disablePriceFilter: boolean;

    constructor(public productsService: ProductsService,
                public vatTypesService: VatTypesService,
                public unitsService: UnitsService,
                public creditorsService: CreditorsService,
                public priceGroupsService: PriceGroupsService,
                public productFieldsService: ProductFieldsService,
                public referencesService: ReferencesService,
                public activatedRoute: ActivatedRoute,
                public ngbModal: NgbModal,
                public router: Router,
                public attachmentService: AttachmentsService,
                public languagesService: LanguagesService,
    ) {
    }

    ngOnInit(): void {
        this.languagesService.getLanguages().subscribe(() => {
            /**
             * Shouldn't be necessary but languages isn't loaded from parent
             */
        });
    }

    public reloadProductFields(): void {
        const productCategoryIds = this._product.product_categories.map(({id}) => id);

        if (productCategoryIds.length > 0) {
            this.loading = true;

            this.productFieldsService.getProductFields(null, productCategoryIds).subscribe(response => {
                this.productFields = response.data;

                this.applyProductFieldsToProduct(this.productFields, this._product);

                this.loading = false;
            });
        } else {
            this.productFields = [];
        }
    }

    public shouldShowProductField(productField: ProductField): boolean {
        const index = this.productFields.findIndex((element) => {
            return element.id === productField.id;
        });

        return index >= 0;
    }

    public addPrice(): void {
        const modalRef = this.ngbModal.open(ProductPriceAddModalComponent, {size: 'lg'});
        modalRef.componentInstance.product = this._product;
    }

    public addQuantityDiscount(): void {
        const modalRef = this.ngbModal.open(ProductQuantityDiscountModalComponent, {size: 'lg'});
        modalRef.componentInstance.product = this._product;
    }

    public editQuantityDiscount(productQuantityDiscount: ProductQuantityDiscount): void {
        const modalRef = this.ngbModal.open(ProductQuantityDiscountModalComponent, {size: 'lg'});
        modalRef.componentInstance.product = this._product;
        modalRef.componentInstance.productQuantityDiscount = productQuantityDiscount;
    }

    public addAttachment(): void {
        const modalRef = this.ngbModal.open(AttachmentUploadModalComponent, {size: 'lg'});
        modalRef.componentInstance.productId = this._product.id;
        modalRef.result.then((attachment: Attachment) => {
            this._product.attachments.push(attachment);
        });
    }

    public deleteQuantityDiscount(productQuantityDiscount: ProductQuantityDiscount): void {
        const index = this._product.product_quantity_discounts.findIndex((element) => {
            return element.id === productQuantityDiscount.id;
        });

        if (index >= 0) {
            this._product.product_quantity_discounts.splice(index, 1);
        }
    }

    public addCategory(): void {
        const self = this;

        const modalRef = this.ngbModal.open(ProductCategorySelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.categorizable = this._product;
        modalRef.componentInstance.selected = (_) => {
            self.reloadProductFields();
        };
    }

    public removeCategory(productCategoryIndex: number): void {
        this._product.product_categories.splice(productCategoryIndex, 1);

        this.reloadProductFields();
    }

    public addProductRelation(childRelation: boolean): void {
        const self = this;

        const productRelation = ProductRelation.fromJSON({
            childRelation: childRelation,
            product_id: childRelation ? this._product.id : null,
            related_product_id: childRelation ? null : this._product.id,
            note: ''
        });

        const modalRef = this.ngbModal.open(ProductSelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.productRelatable = productRelation;
        modalRef.componentInstance.selected = (productRelation) => {
            if (childRelation) {
                self._product.product_relation_childrens.push(productRelation);
            } else {
                self._product.product_relation_parents.push(productRelation);
            }
        };
    }

    public addAsFollowProduct(productRelation: ProductRelation): void {
        productRelation.follow_requirement_minimum = 1;
        productRelation.follow_amount = 1;
    }

    public addProductStock(): void {
        const modalRef = this.ngbModal.open(ProductStockAddModalComponent, {size: 'lg'});
        modalRef.componentInstance.product = this._product;
    }

    public save(): void {
        this.saving = true;
        // Save _products_product_fields back into _product
        this._product.products_product_fields = this._products_product_fields;
        this.saveLanguages();

        if (this.productId) {
            this.productsService.updateProduct(this._product).subscribe(response => {
                this.saving = false;

                // @todo dosnt seem to refresh when saving

                this.router.navigateByUrl('/products/' + response.data.id);
            }, error => {
                this.saving = false;

                console.log(error.error.error);

                alert($localize`Something went wrong, please try again`);
            });
        } else {
            this.productsService.addProduct(this._product).subscribe(response => {
                this.saving = false;

                this.router.navigateByUrl('/products/' + response.data.id);
            });
        }
    }

    saveLanguages(): void {
        let i = 0;
        let ii = 0;
        const translations: Translation = new Translation();
        const fieldTranslations = [];
        for (const productField of this._product.product_fields) {
            fieldTranslations[ii] = [];
            ii++;
        }

        // Fill out products_product_fields translations
        i = 0;
        for (const productsProductField of this._product.products_product_fields) {
            const fieldTranslation = new Translation();
            ii = 0;
            this.languagesService.languages.forEach((language) => {

                    const fieldObject = new LocaleTranslation();
                    if (this._product.product_fields[i].is_localized) {
                        fieldObject.value = this.fieldTranslations[i][ii];
                    }
                    fieldObject.locale = language.locale;
                    fieldTranslation[language.locale] = fieldObject;

                ii++;
            });
            fieldTranslations[i] = fieldTranslation;
            i++;
        }
        let iii = 0;
        this._product.products_product_fields.forEach((productField) => {
            productField._translations = fieldTranslations[iii];
            iii++;
        });

        // Fill out product translations
        i = 0;
        this.languagesService.languages.forEach((language) => {
                const object = new LocaleTranslation();
                object.name = this.name[i];
                object.locale = language.locale;
                translations[language.locale] = object;
            this._product._translations = translations;
            i++;
        });
    }

    protected applyProductFieldsToProduct(productFields: ProductField[], product: Product): void {

        for (const productField of productFields) {
            if (!product.hasProductField(productField.id)) {
                product.product_fields.push(ProductField.fromJSON({
                    id: productField.id,
                    name: productField.name,
                    type: productField.type,
                    is_localized: productField.is_localized,
                    _joinData: {
                        value: '',
                        meta: ''
                    }
                }));
            }
        }
    }

    protected readonly faXmark = faXmark;
}
