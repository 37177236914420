import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../services/auth.service';
import {OrderState} from '../services/order-state';
import {OrderStatesService} from '../services/order-states.service';
import {LanguagesService} from '../services/languages.service';
import {Translation} from "../services/translation";
import {LocaleTranslation} from "../services/locale-translation";

@Component({
    selector: 'app-order-state-edit-modal',
    templateUrl: './order-state-edit-modal.component.html',
    styleUrls: ['./order-state-edit-modal.component.scss'],
    providers: [OrderStatesService, LanguagesService]
})
export class OrderStateEditModalComponent implements OnInit {

    public orderState: OrderState;
    // tslint:disable-next-line:variable-name
    public _orderState: OrderState;

    public missingLocalizations;
    public name: string[] = [];

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public orderStatesService: OrderStatesService,
                public authService: AuthService,
                public languagesService: LanguagesService) {
    }

    ngOnInit() {
        this._orderState = this.orderState ? OrderState.fromJSON(this.orderState) : OrderState.fromJSON({});
        this.languagesService.getLanguages().subscribe(() => {
            this.loadLanguages();
        });
    }

    loadLanguages(): void {
        let i = 0;
        this.languagesService.languages.forEach((language) => {
                if (this._orderState._translations) {
                    const translation = this._orderState._translations[language.locale];
                    if (translation) {
                        if (translation.name) {
                            this.name[i] = translation.name;
                        } else {
                            this.name[i] = '';
                        }
                    } else {
                        this.name[i] = '';
                    }
                } else {
                    this.name[i] = '';
                }
            i++;
        });
    }

    close() {
        this.activeModal.dismiss();
    }

    save() {
        this.saving = true;
        this.saveLanguages();

        if (this.orderState) {
            this.orderStatesService.updateOrderState(this._orderState).subscribe(orderState => {
                this.saved();

                this.saving = false;

                this.activeModal.close(orderState);
            });
        } else {
            this.orderStatesService.addOrderState(this._orderState).subscribe(orderState => {
                this.saved();

                this.saving = false;

                this.activeModal.close(orderState);
            });
        }
    }

    saveLanguages(): void {
        let i = 0;
        const translations: Translation = new Translation();
        this.languagesService.languages.forEach((language) => {
            const object = new LocaleTranslation();
            object.name = this.name[i];
            object.locale = language.locale;
            translations[language.locale] = object;
            this._orderState._translations = translations;
            i++;
        });
    }
}
