import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ArticlesService} from '../services/articles.service';
import {Article} from '../services/article';
import {Attachment} from '../services/attachment';
import {AttachmentsService} from '../services/attachments.service';
import * as dayjs from 'dayjs';
import {LanguagesService} from '../services/languages.service';
import {Translation} from '../services/translation';
import {LocaleTranslation} from "../services/locale-translation";


@Component({
    selector: 'app-article-edit',
    templateUrl: './article-edit.component.html',
    styleUrls: ['./article-edit.component.scss'],
    providers: [ArticlesService, AttachmentsService, LanguagesService]
})
export class ArticleEditComponent implements OnInit {

    constructor(public articlesService: ArticlesService,
                public activatedRoute: ActivatedRoute,
                public router: Router,
                public languagesService: LanguagesService) {
    }

    isDraft: boolean;

    article: Article;
    title = [];
    body = [];

    loading = false;
    saving = false;
    file: File = null;
    date: any;
    missingLocalizations;

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.load(params.get('articleId'));
        });
        this.languagesService.getLanguageIndex().subscribe((response) => {
            this.updateBody();
        });
    }

    public load(id): void {
        /**
         * Unset order, it could be set already if we navigate to another product
         */
        this.article = null;

        /**
         * Set article ID and trigger a load of content
         */
        this.isDraft = !id;

        this.languagesService.getLanguages().subscribe(() => {
            if (!this.isDraft) {
                this.articlesService.getArticle(id).subscribe(response => {
                    this.article = response.data;
                    this.missingLocalizations = this.article._translations.checkMissingLocalizations(this.languagesService.languages, 'title');
                    this.loadLanguages();
                    this.date = this.article.modified.format('YYYY-MM-DD');
                });
            } else {
                this.articlesService.addArticleDraft().subscribe(response => {
                    this.article = response.data;
                    // Localizations are missing for everything but primary
                    this.missingLocalizations = {
                        languages: [true],
                        complete: false,
                    };
                    this.loadLanguages();
                });
            }
        });

    }

    loadLanguages(): void {
        let i = 0;
        this.languagesService.languages.forEach((language) => {

                if (this.article._translations) {
                    const translation = this.article._translations[language.locale];
                    if (translation) {
                        if (translation.title) {
                            this.title[i] = translation.title;
                        } else {
                            this.title[i] = '';
                        }
                        if (translation.body) {
                            this.body[i] = translation.body;
                        } else {
                            this.body[i] = '';
                        }
                    } else {
                        this.title[i] = '';
                        this.body[i] = '';
                    }
                } else {
                    this.title[i] = '';
                    this.body[i] = '';
                }
            i++;
        });
    }

    public save(): void {
        this.saving = true;
        if (!this.article.title) {
            alert('Article must have a title');
            this.saving = false;
            return;
        }

        // Save editor contents in body and date from date picker
        this.saveLanguages();
        this.article.modified = dayjs(this.date, 'YYYY-MM-DD');

        this.articlesService.updateArticle(this.article).subscribe(response => {
            this.saving = false;
            if (this.isDraft) {
                this.router.navigateByUrl('/articles/' + response.data.id);
            } else {
                this.load(response.data.id);
            }
        }, error => {
            this.saving = false;
            console.log(error);
            alert(error.error.error);
        });
    }

    saveLanguages(): void {
        let i = 0;
        const translations: Translation = new Translation();
        this.languagesService.languages.forEach((language) => {
            const object = new LocaleTranslation();
            object.title = this.title[i];
            object.body = this.body[i];
            object.locale = language.locale;
            translations[language.locale] = object;
            i++;
        });
        this.article._translations = translations;
    }

    public onChange(event): void {
        this.file = event.target.files[0];
        event.target.value = null;
    }

    public onUpload(): void {
        this.saving = true;
        const attachment = new Attachment();
        attachment.sort = 0;
        this.articlesService.attachment(attachment, this.file, this.article.id).subscribe(
            (data) => {
                const att: any = data.data;
                this.article.attachments.push(data.data);
                this.file = undefined;
                this.saving = false;
            }
        );
    }

    public bodyChange(event): void {
        this.body[this.languagesService.index] = event;
    }

    public updateBody(): void {
        document.querySelector('.ql-editor').innerHTML = this.body[this.languagesService.index];
    }
}
