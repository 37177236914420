import {Component, OnInit} from '@angular/core';
import {Unit} from '../services/unit';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UnitsService} from '../services/units.service';
import {AuthService} from '../services/auth.service';
import {LanguagesService} from '../services/languages.service';
import {Translation} from '../services/translation';
import {LocaleTranslation} from '../services/locale-translation';
import {CurrenciesService} from "../services/currencies.service";
import { Currency } from '../services/currency';

@Component({
    selector: 'app-currencies-edit-modal',
    templateUrl: './currency-edit-modal.component.html',
    styleUrls: ['./currency-edit-modal.component.scss'],
    providers: [CurrenciesService, LanguagesService]
})
export class CurrencyEditModalComponent implements OnInit {

    public currency: Currency;
    // tslint:disable-next-line:variable-name
    public _currency: Currency;
    // name: string[] = [];

    public saving = false;
    public saved;

    constructor(public activeModal: NgbActiveModal,
                public currenciesService: CurrenciesService,
                public authService: AuthService,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this._currency = this.currency ? Currency.fromJSON(this.currency) : Currency.fromJSON({is_default: false});
    }

    close(): void {
        this.activeModal.dismiss();
    }

    save(): void {
        this.saving = true;
        // this.saveLanguages();
        if (this.currency) {
            this.currenciesService.updateCurrency(this._currency).subscribe(unit => {
                this.saved();

                this.saving = false;

                this.activeModal.close(unit);
            });
        } else {
            this.currenciesService.addCurrency(this._currency).subscribe(unit => {
                this.saved();

                this.saving = false;

                this.activeModal.close(unit);
            });
        }
    }
}
