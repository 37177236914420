<ng-container >
    <div class="row">
        <div class="col-12  col-md-6  mb-4" *ngIf="offerService.selectedTool">
            <h4 class="heading  mb-0">Information:</h4>
            <ul class="list-group" >
                <li class="list-group-item">Maskine:
                    <strong>{{offerService.selectedTool.product.name}}</strong>
                </li>
                <li class="list-group-item">Pris:
                    <strong>{{(preview ? offerService.selectedTool.price : offerService.selectedTool.price_adjusted)|currency:'DKK'}}</strong>

                    <span *ngIf="!preview" class="font-italic"><br/>
                        <span [innerHTML]="offerService.selectedTool.price_adjustment_name | safeHtml"></span><br/>
                        ({{(offerService.selectedTool.price - offerService.selectedTool.price_adjusted) | currency:'DKK'}})</span>
                </li>
                <li class="list-group-item">
                    <fa-icon [icon]="faCalendar"></fa-icon>
                    <ng-container i18n>Production week:</ng-container> <strong>
                    <ng-container *ngIf="productionLineSlot">
                        Uge {{productionLineSlot.date.format('W')}}-{{productionLineSlot.date.format('YYYY')}}<br/>
                    </ng-container>
                    <ng-container *ngIf="!productionLineSlot">
                        <span class="text-muted" i18n>Production week unknown</span>
                    </ng-container>
                </strong></li>
            </ul>
        </div>
        <div class="col-12  col-md-6  mb-4" *ngIf="offerService.selectedOptions && offerService.selectedOptions.length > 0">
            <h4 class="heading  mb-0" i18n>Optional equipment:</h4>
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let extra of offerService.selectedOptions">
                    <span class="float-left">{{extra.product.name}}</span>
                    <span class="float-right text-right">{{(preview ? extra.price : extra.price_adjusted) |currency:'DKK'}}
                        <span *ngIf="!preview && (extra.price * extra.quantity - extra.quantity * extra.price_adjusted) > 0" class="font-italic"><br/>
                            <span [innerHTML]="extra.price_adjustment_name | safeHtml"></span>
                            <br/>
                            ({{(extra.price * extra.quantity - extra.quantity * extra.price_adjusted) | currency:'DKK'}})
                        </span>
                    </span>
                </li>
            </ul>
        </div>
    </div>
    <div class="row" *ngIf="offerService.selectedWheel || offerService.selectedExtraTools.length > 0">
        <div class="col-12  col-md-6  mb-4" *ngIf="offerService.selectedWheel">
            <h4 class="heading  mb-0" i18n>Wheels:</h4>
            <ul class="list-group">
                <li class="list-group-item">
                    <span class="float-left">{{offerService.selectedWheel.product.name}}</span>
                    <span
                        class="float-right text-right">{{(preview ? offerService.selectedWheel.price : offerService.selectedWheel.price_adjusted) | currency:'DKK'}}

                        <span *ngIf="!preview && (offerService.selectedWheel.price - offerService.selectedWheel.price_adjusted) > 0" class="font-italic"><br/>
                            <span [innerHTML]="offerService.selectedWheel.price_adjustment_name | safeHtml" ></span>
                            <br/>({{(offerService.selectedWheel.price - offerService.selectedWheel.price_adjusted) | currency:'DKK'}})</span>

                    </span>
                </li>
            </ul>
        </div>
        <div class="col-12  col-md-6  mb-4" *ngIf="offerService.selectedExtraTools.length > 0">
            <h4 class="heading  mb-0" i18n>Tools:</h4>
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let extra of offerService.selectedExtraTools">
                    <span class="float-left">{{extra.quantity}} x {{extra.product.name}}</span>
                    <span class="float-right text-right">{{(preview ? (extra.price * extra.quantity): (extra.price_adjusted * extra.quantity)) | currency:'DKK'}}

                        <span *ngIf="!preview && (extra.price *extra.quantity - extra.quantity * extra.price_adjusted) > 0" class="font-italic"><br/><span [innerHTML]="extra.price_adjustment_name | safeHtml" ></span><br/>({{(extra.price *extra.quantity - extra.quantity * extra.price_adjusted) | currency:'DKK'}})</span>

                    </span>
                </li>
            </ul>
        </div>
    </div>

    <label for="comment" class="mb-0"><span i18n>Order comment</span>:</label>
    <textarea id="comment" rows="4" class="form-control mb-4"  [(ngModel)]="offerService.comment" (ngModelChange)="updateComment($event)"></textarea>
    <div class="row">
        <div class="col  text-center">
            <h3 class="heading  text-center  d-block"><ng-container i18n>Total price:</ng-container> {{(preview ? totals.price : totals.price_adjusted) | currency:'DKK'}}</h3>
            <ng-container *ngIf="!preview">
                <p>Rabat: {{totals.savings|currency:'DKK'}}</p>
            </ng-container>
            <p *ngIf="preview">
                <button (click)="printOffer()" class="btn  btn-primary  btn-lg" i18n>Print offer</button>
            </p>
        </div>
    </div>
    <div class="row" *ngIf="!preview">
        <div class="col  text-center">
            <p><button class="btn  btn-primary  btn-lg" (click)="placeOrder()" *ngIf="!offerService.completed" [disabled]="processing">Bekræft bestilling</button></p>

            <p *ngIf="offerService.completed"><small>Vi er meget glade for dit valg omkring bestilling, og vi vil hurtigst muligt behandle din ordre og <br />
                vende tilbage til dig med opdateringer omkring status, leveringstid og faktura.</small></p>

            <p *ngIf="offerService.completed">Tilbud nr. {{order?.getIdentificationReference()}} modtaget. <a routerLink="/order/{{ order.id }}">Se detaljer</a></p>
        </div>
    </div>
</ng-container>
