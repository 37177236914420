import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../services/auth.service';
import {OrderLineFieldsService} from '../services/order-line-fields.service';
import {OrderLineField} from '../services/order-line-field';
import {LanguagesService} from '../services/languages.service';
import {Translation} from '../services/translation';
import {LocaleTranslation} from '../services/locale-translation';

@Component({
    selector: 'app-order-line-field-edit-modal',
    templateUrl: './order-line-field-edit-modal.component.html',
    styleUrls: ['./order-line-field-edit-modal.component.scss'],
    providers: [OrderLineFieldsService, LanguagesService]
})
export class OrderLineFieldEditModalComponent implements OnInit {

    public orderLineField: OrderLineField;
    public _orderLineField: OrderLineField;

    public saving = false;
    public saved;

    public missingLocalizations;
    public name: string[] = [];

    constructor(public activeModal: NgbActiveModal,
                public orderLineFieldsService: OrderLineFieldsService,
                public authService: AuthService,
                public languagesService: LanguagesService) {
    }

    ngOnInit(): void {
        this._orderLineField = this.orderLineField ? OrderLineField.fromJSON(this.orderLineField) : OrderLineField.fromJSON({
            type: 'text'
        });
        this.languagesService.getLanguages().subscribe(() => {
            this.loadLanguages();
        });
    }

    loadLanguages(): void {
        let i = 0;
        this.languagesService.languages.forEach((language) => {
                if (this._orderLineField._translations) {
                    const translation = this._orderLineField._translations[language.locale];
                    if (translation) {
                        if (translation.name) {
                            this.name[i] = translation.name;
                        } else {
                            this.name[i] = '';
                        }
                    } else {
                        this.name[i] = '';
                    }
                } else {
                    this.name[i] = '';
                }

            i++;
        });
    }

    close(): void {
        this.activeModal.dismiss();
    }

    save(): void {
        this.saving = true;
        this.saveLanguages();

        if (this.orderLineField) {
            this.orderLineFieldsService.updateOrderLineField(this._orderLineField).subscribe(orderLineField => {
                this.saved();

                this.saving = false;

                this.activeModal.close(orderLineField);
            });
        } else {
            this.orderLineFieldsService.addOrderLineField(this._orderLineField).subscribe(orderLineField => {
                this.saved();

                this.saving = false;

                this.activeModal.close(orderLineField);
            });
        }
    }

    saveLanguages(): void {
        let i = 0;
        const translations: Translation = new Translation();
        this.languagesService.languages.forEach((language) => {

                const object = new LocaleTranslation();
                object.name = this.name[i];
                object.locale = language.locale;
                translations[language.locale] = object;

            this._orderLineField._translations = translations;
            i++;
        });
    }
}
