import {Component, Input, OnInit} from '@angular/core';
import {OfferGeneratorService} from '../../services/offer-generator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OrdersService} from '../../services/orders.service';
import {Order} from '../../services/order';
import {OrderLine} from '../../services/order-line';
import {IdValuesService} from '../../services/themes/helms/id-values.service';
import {forkJoin, of} from 'rxjs';
import {HelmsOrder} from '../../services/themes/helms/helms-order';
import {ReservationsService} from '../../services/themes/helms/reservations.service';
import {faInfoCircle, faSortUp, faSortDown, faSort} from '@fortawesome/free-solid-svg-icons';
import {MachinesService} from '../../services/themes/helms/machines.service';
import {AuthService} from '../../services/auth.service';
import {ProductsService} from "../../services/products.service";

class OrderGroup {
    orders: HelmsOrder[];
    name: string;

    constructor(name, orders) {
        this.name = name;
        this.orders = orders;
    }
}

@Component({
    selector: 'app-ready-orders',
    templateUrl: './ready-orders.component.html',
    styleUrls: ['./ready-orders.component.scss'],
    providers: [OrdersService, ReservationsService, MachinesService, ProductsService]
})
export class ReadyOrdersComponent implements OnInit {

    public orderGroups: OrderGroup[];
    private piplineOrders: Order[];
    private stockOrders: Order[];

    public faInfoCircle = faInfoCircle;
    public faSortUp = faSortUp;
    public faSortDown = faSortDown;
    public faSort = faSort;

    public standalone: boolean;

    filter = {
        sortValue: '',
        sortKey: '',
        sortType: '',
        direction: 'DESC',
        option: '',
        forSelf: false,
    };
    private page = 1;
    private mode: any;

    constructor(
        public productsService: ProductsService,
        public activatedRoute: ActivatedRoute,
        public authService: AuthService,
        public machinesService: MachinesService,
        public reservationsService: ReservationsService,
        public idValuesService: IdValuesService,
        public activatedroute: ActivatedRoute,
        public offerService: OfferGeneratorService, private router: Router, public ordersService: OrdersService) {

        if (this.activatedroute.snapshot.data && this.activatedroute.snapshot.data.standalone) {
            this.standalone = true;
            this.mode = this.activatedroute.snapshot.data.mode;
            this.offerService.reset(false);

            this.activatedRoute.queryParams.subscribe(params => {
                if (!isNaN(params.page)) {
                    this.page = +params.page;

                    this.filter.sortKey = params['sort-key'];
                    this.filter.sortValue = params['sort-value'];
                    this.filter.direction = params.direction;
                    this.filter.sortType = params['sort-type'];
                    this.filter.option = params.option;

                    this.loadData();
                }
            });
        }
    }


    changeFilter(page: number, sortKey?: string, sortValue?: string, sortType?: string, flip?: boolean, option?: string): void {
        if (flip) {
            if (this.filter.sortValue === sortValue) {
                if (this.filter.direction === 'DESC') {
                    this.filter.direction = 'ASC';
                } else {
                    this.filter.direction = 'DESC';
                }
            } else {
                this.filter.direction = 'DESC';
            }
        }

        if (sortValue) {
            this.filter.sortKey = sortKey;
            this.filter.sortValue = sortValue;
        }
        if (sortType) {
            this.filter.sortType = sortType;
        }
        if (option) {
            this.filter.option = option;
        }

        this.loadData();
    }

    loadData(): void {

        const inventoryLimit = -1;
        const pipelineLimit = -1;
        // if (this.standalone && this.mode === 'inventory') {
        //     inventoryLimit = 10;
        // } else if (this.standalone) {
        //     inventoryLimit = 0;
        // }
        //
        // console.log(this.mode);
        //
        // if (this.standalone && this.mode === 'pipeline') {
        //     pipelineLimit = 10;
        // } else if (this.standalone) {
        //     pipelineLimit = 0;
        // }

        forkJoin([
            this.ordersService.getOrders<HelmsOrder>(this.page, inventoryLimit,
                true, false, false, false, false, true, false, false, {
                    forState: '01190160-e140-4129-b711-13f0c5d7a3f6', // lagermaskiner
                    forProducts: this.offerService.selectedTool ? this.offerService.selectedTool.product_id : null,
                    withDates: true,
                    limitedOrderLines: true
                }, this.filter, HelmsOrder
            ),
            this.ordersService.getOrders<HelmsOrder>(this.page, pipelineLimit,
                true, false, false, false, false, true, false, false, {
                    forState: '46ff26ba-ecbc-428f-bd56-fe7516f31c03', // pipeline
                    forProducts: this.offerService.selectedTool ? this.offerService.selectedTool.product_id : null,
                    withDates: true,
                    limitedOrderLines: true
                }, this.filter, HelmsOrder
            )
        ]).subscribe((results) => {

            if (results[0].data.length > 0 || results[1].data.length > 0) {
                this.orderGroups = [];
                if (this.offerService.selectedOrderId) {
                    this.offerService.pausenext = false;
                } else {
                    this.offerService.pausenext = true;
                }
                if (results[0].data && results[0].data.length > 0) {
                    const label = $localize`Machines in stock`;
                    this.orderGroups.push(new OrderGroup(label, results[0].data));
                }
                if (results[1].data && results[1].data.length > 0) {
                    this.orderGroups.push(new OrderGroup('Pipeline', results[1].data));
                }
            } else {
                this.offerService.pausenext = false;
                this.orderGroups = [];
            }
        });
    }

    ngOnInit(): void {
        if (!this.standalone && (!this.offerService.readyToolExists || this.offerService.selectedOrderId === undefined)) {
            this.offerService.selectedOrderId = undefined;
            this.router.navigate(['/', 'offers', {outlets: {offer_outlet: ['custom-order']}}]);
        } else {
            this.loadData();
        }
    }

    pickStock(order: Order): void {

        // if we are standalong, we need to fetch the full product of the machine first
        const preFetch = [this.productsService.getProduct(this.machinesService.getMachineOrderLine(order).product.id, true, false, true, true, true, true, null,
            true, true, true, true,
            {
                byCategories: this.idValuesService.productMachineCategoryId,
                withProductStocks: true,
                withAdjustedPrices: true,
                fromOrderId: order.id
            })];

        forkJoin(preFetch).subscribe((results) => {

                if (results[0]) {
                    this.offerService.changeSelectedTool(results[0].data);
                    this.offerService.readyToolExists = true;
                } else {
                    alert('kunne ikke hente maskindata, kontakt Helms');
                }

            this.offerService.selectedOrderId = order.id;
            this.offerService.loadSelectedOrder();
            this.offerService.selectedOptions = [];
            order.order_lines.forEach((el) => {

                // get the price from the current selecgted producct
                const prodrealion = this.offerService.selectedTool.product.product_relation_childrens.find((prod) => {
                    return prod.product.id === el.product_id;
                });

                if (prodrealion) {
                    const orderLine = new OrderLine();
                    orderLine.product_id = prodrealion.product.id;
                    orderLine.quantity = 1;
                    orderLine.product = prodrealion.product;
                    orderLine.price_adjusted = prodrealion.product.adjusted_price;
                    orderLine.price_adjustment_name = prodrealion.product.adjusted_name;
                    orderLine.price = prodrealion.product.price;


                    if (orderLine.product.hasCategory({id: this.idValuesService.wheelCategoryId})) {
                        this.offerService.selectedWheel = orderLine;
                    } else {
                        this.offerService.selectedOptions.push(orderLine);
                    }
                }
            });
            if (this.standalone) {
                this.offerService.currentStepIndex = 2;
                this.router.navigate(['/', 'offers', {outlets: {offer_outlet: ['wheel-select']}}]);
            } else {
                this.offerService.nextStep();
            }
        });
    }

    switchMode(): void {
        this.offerService.selectedOrderId = undefined;
        this.router.navigate(['/', 'offers', {outlets: {offer_outlet: ['custom-order']}}]);
    }

    startReserveration(order): void {
        this.reservationsService.addReservation({order_id: order.id}).subscribe((response) => {
            alert('Reserveret i 24 timer');
            this.loadData();
        }, (error) => {
            if (error?.error?.error?.order_id?.integrity) {
                alert('Max antal reservationer overskredet');
            } else {
                alert('Ukendt fejl');
            }
        });
    }

    endReservation(reservation): void {
        this.reservationsService.deleteReservation(reservation).subscribe((response) => {
            alert('Reservation slettet');
            this.loadData();
        }, (error) => {
        });
    }
}
